import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../utils/context";
import "./GeneratingLoader.scss";

const LINES = [
  "Searching...",
  "Hard Drive located...",
  "Files located in the cloud...",
  'Access code: "HAPPY MONDAY"...',
  "Downloading stem: vocals.wav...",
  "Downloading stem: melody.wav...",
  "Downloading stem: drums.wav...",
  "Downloading stem: 808s.wav...",
  "Downloading stem: FX.wav...",
  "Downloading stem: sample.wav...",
  "Analyzing file: vocals.wav...",
  "Analyzing file: melody.wav...",
  "Analyzing file: drums.wav...",
  "Analyzing file: 808s.wav...",
  "Analyzing file: FX.wav...",
  "Analyzing file: sample.wav...",
  "",
  "// Accessing hard drive on cloud server",
  "async function accessStorage(storageType, credentials) {",
  "  console.log(`ACCESSING THE ${storageType.toUpperCase()}...`);",
  "  await wait(2000); // Simulate wait time",
  '  console.log("Searching...");',
  "  // Authenticate",
  "  const isAuthenticated = await authenticate(credentials);",
  "  if (!isAuthenticated) {",
  '    console.log("Access denied: Invalid credentials.");',
  "    return;",
  "  }",
  "  console.log(`${storageType} located...`);",
  '  console.log("Files located in the cloud...");',
  "  // List of audio files to download",
  "  const audioFiles = [",
  '    "vocals.wav", "melody.wav", "drums.wav",',
  '    "808s.wav", "FX.wav", "sample.wav"',
  "  ];",
  "  // Download and analyze files",
  "  for (const file of audioFiles) {",
  "    console.log(`Downloading file: ${file}...`);",
  "    await downloadFile(file);",
  "    console.log(`Analyzing file: ${file}...`);",
  "    await analyzeFile(file);",
  "  }",
  '  console.log("All files have been downloaded and analyzed.");',
  '  console.log("Unreleased demo will be in your library shortlyツ");',
  "}",
  "// Placeholder functions for authentication, downloading, and analyzing",
  "function authenticate(credentials) {",
  "  return new Promise((resolve) => {",
  "    setTimeout(() => {",
  '      resolve(credentials.accessCode === "HAPPY MONDAY");',
  "    }, 1000);",
  "  });",
  "}",
  "function downloadFile(fileName) {",
  "  return new Promise((resolve) => {",
  "    setTimeout(() => {",
  "      console.log(`${fileName} downloaded.`);",
  "      resolve();",
  "    }, 1500);",
  "  });",
  "}",
  "function analyzeFile(fileName) {",
  "  return new Promise((resolve) => {",
  "    setTimeout(() => {",
  "      console.log(`${fileName} analyzed.`);",
  "      resolve();",
  "    }, 1000);",
  "  });",
  "}",
  "function wait(ms) {",
  "  return new Promise((resolve) => setTimeout(resolve, ms));",
  "}",
  "Unreleased demo will be in your library shortlyツ",
];

const GeneratingLoader = () => {
  const context = useContext(Context);

  const [initialMessageVisible, setInitialMessageVisible] = useState(true);
  const [lineIndex, setLineIndex] = useState(-1);

  useEffect(() => {
    setTimeout(() => {
      setInitialMessageVisible(false);
    }, 10000);
  }, []);

  useEffect(() => {
    if (!initialMessageVisible) {
      setInterval(() => {
        if (lineIndex < LINES.length) setLineIndex((i) => i + 1);
        else this.clear();
      }, 1000);
    }
  }, [initialMessageVisible]);

  return (
    <div className="hard-drive-access">
      <div id="title">ACCESSING THE HARD DRIVE [EST. WAIT TIME 3 MIN]</div>
      {initialMessageVisible && (
        <div id="initial-message">
          DISCLAIMER: ACCESSING UNRELEASED DEMO'S FROM THE HARD DRIVE MAY TAKE
          UP TO 3 MINUTES. YOU CAN LEAVE THIS PAGE AND CHECK BACK IN A FEW
          MINUTES. YOUR DEMO WILL BE AVAILABLE TO SAVE OR DOWNLOAD SHORTLY.
          <br />
          <br />
          NOTE: THIS IS AN UNRELEASED DEMO AND YOU ARE THE ONLY PERSON TO HAVE
          ACCESS TO THIS VERSION OF THE SONG ツ
        </div>
      )}
      <div id="console" className="console">
        {LINES.map((line, index) =>
          index <= lineIndex ? <div key={index}>{line}</div> : <></>
        )}
      </div>
      <div id="loading-indicator" className="loading-indicator"></div>
    </div>
  );
};

export default GeneratingLoader;
