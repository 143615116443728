import { useContext, useState } from "react";
import Close from "./Close";
import Input from "./Input";
import Select from "./Select";
import AppHeader from "./AppHeader";
import Settings from "../../assets/settings.svg";
import Delete from "../../assets/delete.svg";
import "./EnergyLevels.scss";
import { Context } from "../../utils/context";

const EnergyLevels = (props) => {
  const context = useContext(Context);
  const [openModal, setOpenModal] = useState(false);

  const setEnergyLevels = (value, index, type) => {
    context.setCustomEnergyLevels((levels) => {
      const newLevels = [...levels];
      newLevels[index] = { ...newLevels[index], [type]: value };
      return newLevels;
    });

    if (props.setParameters)
      props.setParameters((params) => {
        const newLevels = [...params.energyLevels];
        newLevels[index] = { ...newLevels[index], [type]: value };
        return { ...params, energyLevels: newLevels };
      });
  };

  const removeEnergyLevel = (index) => {
    context.setCustomEnergyLevels((levels) =>
      levels.filter((_, i) => i !== index)
    );

    if (props.setParameters)
      props.setParameters((params) => {
        const newLevels = params.energyLevels.filter((_, i) => i !== index);
        return { ...params, energyLevels: newLevels };
      });
  };

  const addLevel = () => {
    context.setCustomEnergyLevels((levels) => [
      ...levels,
      { start: "0", end: "0", energy: "Medium" },
    ]);

    if (props.setParameters)
      props.setParameters((params) => {
        return {
          ...params,
          energyLevels: [
            ...params.energyLevels,
            { start: "0", end: "0", energy: "Medium" },
          ],
        };
      });
  };

  return (
    <>
      <div
        className={
          context.customEnergyLevels && context.customEnergyLevels.length
            ? "energy_button energy_button--active"
            : "energy_button"
        }
        onClick={() => setOpenModal(true)}
      >
        Energy Levels
        <img src={Settings} alt="Settings" />
      </div>

      {openModal && (
        <div className="energy_modal">
          <div
            className="energy_modal__backdrop"
            onClick={() => setOpenModal(false)}
          ></div>

          <div className="energy_modal__container">
            <Close onClick={() => setOpenModal(false)} />
            <AppHeader text="Energy Levels" />

            <div className="energy_modal__items">
              {context.customEnergyLevels.map((e, i) => (
                <div
                  className="energy_modal__item"
                  style={{ zIndex: context.customEnergyLevels.length - i }}
                >
                  <Input
                    placeholder="Start"
                    value={e.start}
                    type="text"
                    onInput={(event) =>
                      setEnergyLevels(event.target.value, i, "start")
                    }
                  />
                  <Input
                    placeholder="End"
                    value={e.end}
                    type="text"
                    onInput={(event) =>
                      setEnergyLevels(event.target.value, i, "end")
                    }
                  />
                  <Select
                    data={[
                      { value: "Muted", name: "Muted" },
                      { value: "Low", name: "Low" },
                      { value: "Medium", name: "Medium" },
                      { value: "High", name: "High" },
                      { value: "Very High", name: "Very High" },
                    ]}
                    default={e.energy}
                    onChange={(value) => setEnergyLevels(value, i, "energy")}
                  />
                  <div
                    className="energy_modal__del"
                    onClick={() => removeEnergyLevel(i)}
                  >
                    <img src={Delete} alt="" />
                  </div>
                </div>
              ))}
            </div>

            <div className="energy_modal__buttons">
              <div className="button" onClick={addLevel}>
                Add A Level
              </div>
              <div className="button" onClick={() => setOpenModal(false)}>
                Save
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EnergyLevels;
