import { useContext } from "react";
import { Context } from "../../utils/context";
import { useNavigate } from "react-router-dom";
import "./Notification.scss";

const Notification = (props) => {
  const context = useContext(Context);
  const navigate = useNavigate();

  return (
    <div
      onClick={() => !context.admin && navigate("/subscribe?no_redirect=true")}
      className={
        context.error ? "notification notification--error" : "notification"
      }
    >
      {context.error ? context.error : props.text}
    </div>
  );
};

export default Notification;
