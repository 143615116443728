import { useContext, useState, useEffect } from "react";
import { Context } from "../../utils/context";
import { useHttpClient } from "../../utils/http-hook";
import { firebaseDelete } from "../../utils/upload-hook";
import Notification from "../shared/Notification";
import AppHeader from "../shared/AppHeader";
import Post from "./components/Post";
import Loader from "../shared/Loader";
import "./Community.scss";

const Community = () => {
  const context = useContext(Context);
  const [max, setMax] = useState(1);
  const [step, setStep] = useState(0);

  const { sendRequest, isLoading } = useHttpClient();

  const fetchPosts = async (reset) => {
    const responseData = await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/community/get-posts",
      "POST",
      JSON.stringify({
        step: reset ? 0 : step,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }

      let posts = responseData.posts.map((p) => {
        return { ...p, id: p._id };
      });

      setStep(responseData.nextPage);
      setMax(responseData.max);

      if (reset) {
        context.setPosts(posts);
      } else {
        context.setPosts((p) => [...p, ...posts]);
      }
    }
  };

  useEffect(() => {
    context.setPosts([]);
    fetchPosts(true);
  }, []);

  const likePost = (postId, userId) => {
    context.setPosts((prevPosts) => {
      // Copy the current state immutably
      const updatedPosts = prevPosts.map((post) => {
        if (post.id === postId) {
          // Toggle like
          const isLiked = post.likes.includes(userId);
          const updatedLikes = isLiked
            ? post.likes.filter((likeId) => likeId !== userId)
            : [...post.likes, userId];

          // Return a new post object with updated likes
          return { ...post, likes: updatedLikes };
        }
        return post;
      });

      // Return the updated posts without sorting them
      return updatedPosts;
    });
  };

  const updateComments = (postId, comments) => {
    context.setPosts((ps) => {
      const activePost = { ...ps.find((c) => c.id === postId) };
      const posts = ps.filter((c) => c.id !== postId);

      activePost.comments = comments;

      posts.push(activePost);
      posts.sort((a, b) => new Date(b.date) - new Date(a.date));

      return posts;
    });
  };

  const deleteHandler = async (postId) => {
    let post = context.posts.find((p) => p.id === postId);

    if (post.imgPath) {
      firebaseDelete(post.imgPath);
    }

    context.setPosts((posts) => posts.filter((p) => p.id !== postId));
    await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/community/",
      "DELETE",
      JSON.stringify({
        postId,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.token,
      }
    );
  };

  const pinHandler = async (postId, pinned) => {
    context.setPosts((posts) => {
      posts.find((p) => p.id === postId).pinned = pinned;
      return posts;
    });

    await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/community/pin",
      "POST",
      JSON.stringify({
        postId,
        pinned: pinned,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.token,
      }
    );
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    if (
      scrollTop + clientHeight >= scrollHeight - 50 &&
      !isLoading &&
      max > step
    ) {
      fetchPosts(false);
    }
  };

  return (
    <div
      className="col col__1 community"
      style={{ display: context.tab === "community_comments" ? "none" : "" }}
      onScroll={handleScroll}
    >
      <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      <AppHeader text="Community" />

      {context.posts &&
        context.posts.length > 0 &&
        context.posts.map((p) => (
          <Post
            {...p}
            key={p.id}
            updateComments={updateComments}
            likePost={likePost}
            deleteHandler={deleteHandler}
            pinHandler={pinHandler}
          />
        ))}

      {isLoading && <Loader />}
    </div>
  );
};

export default Community;
