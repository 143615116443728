import { useContext, useRef, useState } from "react";
import { Context } from "../../../utils/context";
import Input from "../../shared/Input";
import Select from "../../shared/Select";
import Placeholder from "../../../assets/placeholder-tile.jpg";
import Button from "../../shared/Button";
import "./SongForm.scss";

const SongForm = (props) => {
  const {
    isLoading,
    formState,
    setFormState,
    submitHandler,
    originalId,
    setOriginalId,
    savedSongImg,
    AIEnabled,
  } = props;

  const ref = useRef();
  const audioRef = useRef();
  const context = useContext(Context);

  // Setup image and song name state
  const [songImg, setSongImg] = useState(savedSongImg || Placeholder);
  const [songName, setSongName] = useState("Upload audio file");

  // Image file change handler
  const fileImgChangeHandler = () => {
    if (ref.current.files[0]) {
      context.setError("");
      if (ref.current.files[0].size > 5000000) {
        context.setError("Max img size: 5MB");
        ref.current.value = "";
        return;
      }
      const fileReader = new FileReader();
      fileReader.onload = () => setSongImg(fileReader.result);
      fileReader.readAsDataURL(ref.current.files[0]);
    } else {
      setSongImg(Placeholder);
    }
  };

  // Audio file change handler
  const fileAudioChangeHandler = () => {
    context.setError("");
    const files = audioRef.current.files;
    setSongName(files.length > 0 ? files[0].name : "Upload audio file");
  };

  // Submit handler
  const submitSongHandler = (e) => {
    e.preventDefault();

    if (
      context.setTab === "upload_song" &&
      (!ref.current.value || !audioRef.current.value)
    ) {
      return;
    }
    submitHandler(ref.current.files[0], audioRef.current.files[0]);
  };

  const addAIHandler = (e) => {
    e.preventDefault();
    context.setTab("edit_song_ai");
  };

  return (
    <form className="song_form" onSubmit={submitSongHandler}>
      <div className="song_form__container">
        <div className="song_form__inputs">
          <Input
            placeholder="Song name"
            value={formState.songName}
            type="text"
            onInput={(e) => {
              setFormState((state) => {
                return { ...state, songName: e.target.value };
              });
            }}
            required
          />
          <Input
            placeholder="Artist"
            value={formState.artist}
            type="text"
            onInput={(e) => {
              setFormState((state) => {
                return { ...state, artist: e.target.value };
              });
            }}
            required
          />
          <Input
            placeholder="Producer"
            value={formState.producer}
            type="text"
            onInput={(e) => {
              setFormState((state) => {
                return { ...state, producer: e.target.value };
              });
            }}
            required
          />
          <Input
            placeholder="Label"
            value={formState.label}
            type="text"
            onInput={(e) => {
              setFormState((state) => {
                return { ...state, label: e.target.value };
              });
            }}
            required
          />
          <Select
            data={[
              { name: "Set as backup", value: "" },
              ...context.songs.map((s) => {
                return { name: `${s.name} - ${s.artist}`, value: s._id };
              }),
            ]}
            onChange={(value) => setOriginalId(value)}
            default={originalId}
          />
        </div>

        <div className="song_form__upload_wrapper">
          <label htmlFor="img_file">
            <div className="song_form__upload">
              <img src={songImg} alt="Upload song" />
              <p>Upload Song Picture</p>
              <input
                onChange={fileImgChangeHandler}
                accept="image/*"
                type="file"
                ref={ref}
                id="img_file"
              />
            </div>
          </label>

          <label htmlFor="audio_file">
            <div className="song_form__upload song_form__upload--audio">
              <p>{songName}</p>
              <input
                onChange={fileAudioChangeHandler}
                accept="audio/*"
                type="file"
                ref={audioRef}
                id="audio_file"
              />
            </div>
          </label>
        </div>
      </div>

      <div className="song_form__buttons">
        <Button disabled={isLoading} text="Save" />

        {props.songId && (
          <Button
            disabled={isLoading || !formState.isValid}
            text={AIEnabled ? "Edit Stems" : "Upload Stems"}
            onClick={addAIHandler}
          />
        )}
      </div>
    </form>
  );
};

export default SongForm;
