import Placeholder from "../../../assets/placeholder-tile.jpg";

const GenerateInstructions = () => {
  return (
    <div className="song_preview song_preview--generate">
      <div className="song_preview__img_container">
        <img
          className="song_preview__img song_preview__img--loader"
          src={Placeholder}
          alt=""
        />
      </div>
      <p>1] SELECT SONG</p>
      <p>2] CHOOSE ONE BUTTON</p>
      <p>3] CLICK GENERATE</p>
    </div>
  );
};

export default GenerateInstructions;
