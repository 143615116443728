import { useContext, useState, useEffect } from "react";
import ButtonLink from "../../shared/ButtonLink";
import { useNavigate } from "react-router-dom";
import SubscribeTile from "./SubscribeTile";
import { Context } from "../../../utils/context";
import AppHeader from "../../shared/AppHeader";
import CancelSubscription from "../../account-settings/components/CancelSubscription";
import "./SubscribeBody.scss";

const TRIAL_PLANS = [
  {
    price: "$1/MO",
    link: "https://buy.stripe.com/bIY8yp2TheM77eg5kk",
    features: [
      "FREE TRIAL [48H]",
      "HARD DRIVE ACCESS",
      "PRIVATE COMMUNITY",
      "[0] MPFIVE CREDITS",
    ],
  },
  {
    price: "$15/MO",
    link: "https://buy.stripe.com/9AQ01T51pdI3gOQ145",
    features: [
      "FREE TRIAL [48H]",
      "HARD DRIVE ACCESS",
      "PRIVATE COMMUNITY",
      "[5] MPFIVE CREDITS",
    ],
  },
  {
    price: "$25/MO",
    link: "https://buy.stripe.com/7sIaGx65t9rN1TW5km",
    features: [
      "FREE TRIAL [48H]",
      "HARD DRIVE ACCESS",
      "PRIVATE COMMUNITY",
      "[10] MPFIVE CREDITS",
    ],
  },
];

const PLANS = [
  {
    price: "$1/MO",
    link: "https://buy.stripe.com/eVa7uldxVfQb424006",
    features: [
      "FREE TRIAL [48H]",
      "HARD DRIVE ACCESS",
      "PRIVATE COMMUNITY",
      "[0] MPFIVE CREDITS",
    ],
  },
  {
    price: "$15/MO",
    link: "https://buy.stripe.com/4gwdSJ51pcDZ0PS3cj",
    features: [
      "FREE TRIAL [48H]",
      "HARD DRIVE ACCESS",
      "PRIVATE COMMUNITY",
      "[5] MPFIVE CREDITS",
    ],
  },
  {
    price: "$25/MO",
    link: "https://buy.stripe.com/8wM9CtbpNbzV2Y028g",
    features: [
      "FREE TRIAL [48H]",
      "HARD DRIVE ACCESS",
      "PRIVATE COMMUNITY",
      "[10] MPFIVE CREDITS",
    ],
  },
];

const CREDITS = [
  {
    link: "https://buy.stripe.com/9AQg0R9hF5bx424004",
    price: "$5",
    features: [
      "REQUIRES PLAN",
      "[1] MPFIVE CREDIT",
      "ONE-TIME FEE",
      "PURCHASE AS NEEDED",
    ],
  },
  {
    link: "https://buy.stripe.com/dR63e53Xl33p5689AF",
    price: "$50",
    features: [
      "REQUIRES PLAN",
      "[25] MPFIVE CREDITS",
      "ONE-TIME FEE",
      "PURCHASE AS NEEDED",
    ],
  },
];

const SubscribeBody = () => {
  const navigate = useNavigate();
  const context = useContext(Context);
  const [activeLink, setActiveLink] = useState("");
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (!userData) {
      navigate("/login");
    }
  }, [context.token]);

  const activeSub =
    (context.subscriptionActiveUntil &&
      new Date(context.subscriptionActiveUntil) > new Date()) ||
    context.admin;

  return (
    <div className="subscribe_body">
      <div className="subscribe_body__tiles">
        <div className="subsribe_body__row">
          <AppHeader text="Choose a plan" />
          {!context.user.stripeCustomerId &&
            TRIAL_PLANS.map((p) => (
              <SubscribeTile
                {...p}
                onClick={() => setActiveLink(p.link)}
                active={activeLink === p.link}
              />
            ))}

          {context.user.stripeCustomerId &&
            PLANS.map((p) => (
              <SubscribeTile
                {...p}
                onClick={() => setActiveLink(p.link)}
                active={activeLink === p.link}
              />
            ))}
        </div>

        <div className="subsribe_body__row">
          <AppHeader text="Remix Credits" />
          {CREDITS.map((p) => (
            <SubscribeTile
              {...p}
              onClick={() => setActiveLink(p.link)}
              active={activeLink === p.link}
            />
          ))}
        </div>
      </div>

      <ButtonLink
        text="HACK THE HARD DRIVE"
        to={`${activeLink}?prefilled_email=${context.email}`}
        disabled={!activeLink}
      />
      {activeSub &&
        !context.user.subscriptionCanceled &&
        !context.user.admin && (
          <button
            onClick={(e) => {
              e.preventDefault();
              setCancelModalOpen(true);
            }}
            className="link"
          >
            Cancel Subscription
          </button>
        )}
      <ButtonLink text="Back" to="/" />
      {!activeSub && (
        <button className="link" onClick={() => context.logout()}>
          Log out
        </button>
      )}
      {!context.admin && context.user.subscriptionCanceled && (
        <p className="cancel_subscription_par">Your subscription is canceled</p>
      )}

      {cancelModalOpen && (
        <CancelSubscription
          clear={() => setCancelModalOpen(false)}
          cancel={() =>
            context.setUser((u) => {
              return { ...u, subscriptionCanceled: true };
            })
          }
        />
      )}
    </div>
  );
};

export default SubscribeBody;
