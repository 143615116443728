import { useState } from "react";
import Placeholder from "../../../assets/placeholder-tile.jpg";
import Settings from "../../../assets/settings.svg";
import "./UserTile.scss";

const UserTile = (props) => {
  const [openSettings, setOpenSettings] = useState(false);

  const {
    id,
    admin,
    verified,
    img,
    name,
    onAdminChange,
    onVerificationChange,
    index,
    aiCredits,
    deleteUser,
    updateCredits,
  } = props;

  // Handle admin status toggle
  const clickAdminHandler = () => {
    onAdminChange(id, !admin);
  };

  // Handle verification status toggle
  const clickVerifyHandler = () => {
    onVerificationChange(id, !verified);
  };

  const deleteHandler = () => {
    deleteUser(id);
  };

  const toggleSettings = () => {
    setOpenSettings((s) => !s);
  };

  return (
    <div className="user">
      <div className="user__info">
        <img src={img || Placeholder} alt={`${name}'s profile`} />

        <div className="user__text">
          <h3>{name}</h3>
          <span>{admin ? "admin" : "user"}</span>
        </div>
      </div>

      <div className="user_settings" style={{ zIndex: index }}>
        <img
          onClick={toggleSettings}
          className="user_settings__btn"
          src={Settings}
          alt="Settings"
        />

        {openSettings && (
          <div className="user_settings__container">
            <button onClick={clickVerifyHandler}>
              {verified ? "Remove Verification" : "Verify"}
            </button>
            <button onClick={clickAdminHandler}>
              {admin ? "Remove Admin" : "Make Admin"}
            </button>
            {!admin && (
              <div>
                <label htmlFor="credits">Credits</label>
                <input
                  placeholder={aiCredits}
                  type="number"
                  id="credits"
                  min="0"
                  onChange={(e) => {
                    setTimeout(() => updateCredits(id, e.target.value), 300);
                  }}
                />
              </div>
            )}
            <button className="delete" onClick={deleteHandler}>
              Delete User
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserTile;
