import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import ContentContainer from "../shared/ContentContainer";
import Notification from "../shared/Notification";
import AppHeader from "../shared/AppHeader";
import { useHttpClient } from "../../utils/http-hook";
import SubscribeBody from "./components/SubscribeBody";
import "./Subscribe.scss";

const Subscribe = () => {
  const navigate = useNavigate();
  const context = useContext(Context);
  const { sendRequest } = useHttpClient();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (
      ((context.subscriptionActiveUntil &&
        new Date(context.subscriptionActiveUntil) > new Date()) ||
        context.admin) &&
      !urlParams.get("no_redirect")
    ) {
      navigate("/player");
    }
  }, [context.subscriptionActiveUntil]);

  return (
    <ContentContainer>
      <Notification text="PICK A PLAN" />
      <div className="subscribe">
        <SubscribeBody />
      </div>
    </ContentContainer>
  );
};

export default Subscribe;
