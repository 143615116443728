import { useContext } from "react";
import { Context } from "../../../utils/context";
import SpeakerLeft from "../../../assets/player-speakers-left.png";
import SpeakerCenter from "../../../assets/player-speakers-center.png";
import SpeakerRight from "../../../assets/player-speakers-right.png";
import "./PlayerHeader.scss";

const PlayerHeader = () => {
  const context = useContext(Context);

  return (
    <div className="player_header">
      {context.tab !== "generating" && <img src={SpeakerLeft} alt="" />}
      {context.tab !== "generating" && <img src={SpeakerCenter} alt="" />}
      {context.tab !== "generating" && <img src={SpeakerRight} alt="" />}
    </div>
  );
};

export default PlayerHeader;
