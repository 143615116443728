import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import Generate from "../../../assets/player-generate.jpg";
import EditRemix from "../../../assets/edit-remix-button.jpg";
import "./PlayerButtons.scss";

const PlayerButtons = () => {
  const navigate = useNavigate();
  const context = useContext(Context);
  const song = context.songs
    ? context.songs.find((s) => s._id === context.activeSong)
    : null;

  const setModeHandler = (newMode) => {
    if (context.mode === newMode) {
      context.setMode(null);
    } else {
      context.setMode(newMode);
    }
  };

  const generateHandler = () => {
    if (!context.mode) {
      context.setError("Select a mode");

      setTimeout(() => {
        context.setError("");
      }, 3000);

      return;
    }

    if (!song) {
      context.setError("Song is not selected");

      setTimeout(() => {
        context.setError("");
      }, 3000);
      return;
    }

    if (!song.AIEnabled && !song.AIGenerated) {
      context.setError("Song is not selected");

      setTimeout(() => {
        context.setError("");
      }, 3000);
      return;
    }

    if (context.mode === "slow") {
      context.slowDown(context.activeSong);
      return;
    }

    if (context.mode === "fast") {
      context.spedUp(context.activeSong);
      return;
    }

    if (context.admin) {
      context.setTab("generate-admin-settings");
    } else {
      if (context.credits > 0) {
        context.generateSong(context.activeSong, context.mode);
      } else {
        navigate("/subscribe?no_redirect=true");
      }
    }
  };

  const [isPressed, setIsPressed] = useState(false);
  const handleMouseDown = () => {
    setIsPressed(true);
  };
  const handleMouseUp = () => {
    setIsPressed(false);
  };
  const buttonStyle = isPressed ? { transform: "scale(0.95)" } : {};

  return (
    <div className="player_buttons">
      <div className="player_buttons__row">
        <div
          className={
            context.mode === "Hip Hop"
              ? "player_button player_button--active"
              : "player_button"
          }
          onClick={() => setModeHandler("Hip Hop")}
        >
          <div className="player_button__num">01</div>
          <p>HIPHOP</p>
        </div>

        <div
          className={
            context.mode === "Rock"
              ? "player_button player_button--active"
              : "player_button"
          }
          onClick={() => setModeHandler("Rock")}
        >
          <div className="player_button__num">02</div>
          <p>Rock</p>
        </div>

        <div
          className={
            context.mode === "Latin"
              ? "player_button player_button--active"
              : "player_button"
          }
          onClick={() => setModeHandler("Latin")}
        >
          <div className="player_button__num">03</div>
          <p>LATIN</p>
        </div>

        <div
          className={
            context.mode === "R&B"
              ? "player_button player_button--active"
              : "player_button"
          }
          onClick={() => setModeHandler("R&B")}
        >
          <div className="player_button__num">04</div>
          <p>RNB</p>
        </div>

        <div
          className={
            context.mode === "Afrobeats"
              ? "player_button player_button--active"
              : "player_button"
          }
          onClick={() => setModeHandler("Afrobeats")}
        >
          <div className="player_button__num">05</div>
          <p>AFROBEATS</p>
        </div>

        <div
          className={
            context.mode === "House"
              ? "player_button player_button--active"
              : "player_button"
          }
          onClick={() => setModeHandler("House")}
        >
          <div className="player_button__num">06</div>
          <p>DANCE</p>
        </div>
      </div>

      <div className="player_buttons__row">
        <div
          className={
            context.mode === "random"
              ? "player_button player_button--active"
              : "player_button"
          }
          onClick={() => setModeHandler("random")}
        >
          <div className="player_button__num">07</div>
          <p>Random</p>
        </div>

        <div
          className={
            context.mode === "slow"
              ? "player_button player_button--active"
              : "player_button"
          }
          onClick={() => setModeHandler("slow")}
        >
          <div className="player_button__num player_button__num--neutral">
            08
          </div>
          <p>Slow</p>
        </div>

        <div
          className={
            context.mode === "fast"
              ? "player_button player_button--active"
              : "player_button"
          }
          onClick={() => setModeHandler("fast")}
        >
          <div className="player_button__num player_button__num--neutral">
            09
          </div>
          <p>Fast</p>
        </div>

        {(song ? !song.AIGenerated : true) && !context.aiLoading && (
          <div
            className="player_button__generate"
            onClick={generateHandler}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            style={buttonStyle}
          >
            <img src={Generate} alt="Generate" />
          </div>
        )}

        {(song ? song.AIGenerated : false) && !context.aiLoading && (
          <div
            className="player_button__generate"
            onClick={() => {
              context.setActiveRemix(
                context.songs.find((s) => s._id === context.activeSong)
              );
              context.setTab("edit-remix");
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            style={buttonStyle}
          >
            <img src={EditRemix} alt="Edit Remix" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayerButtons;
