import { useContext } from "react";
import { Context } from "../../utils/context";
import AppHeader from "../shared/AppHeader";
import SongsList from "./components/SongsList";
import Notification from "../shared/Notification";
import Loader from "../shared/Loader";

const SongsTab = () => {
  const context = useContext(Context);
  const remixes = context.songs
    ? context.songs.filter((s) => s.creator === context.userId && s.AIGenerated)
    : [];

  const notRemixes = context.songs
    ? context.songs.filter(
        (s) => s.creator !== context.userId || !s.AIGenerated
      )
    : [];

  const favorites =
    context.songs && context.favorites
      ? context.songs.filter((s) => context.favorites.find((f) => f === s._id))
      : [];

  return (
    <div className="col col__1">
      <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      {notRemixes.length !== 0 && (
        <>
          <AppHeader text="Hard drive" />
          <SongsList songs={notRemixes} />
        </>
      )}
      {favorites.length !== 0 && (
        <>
          <AppHeader text="Favorites" />
          <SongsList songs={favorites} />
        </>
      )}

      {!remixes.length && !favorites.length && !notRemixes.length && <Loader />}
    </div>
  );
};

export default SongsTab;
