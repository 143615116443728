import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import Notification from "../shared/Notification";
import ThemeToggle from "../shared/ThemeToggle";
import ModalContainer from "../shared/ModalContainer";
import AppHeader from "../shared/AppHeader";
import Close from "../shared/Close";
import Settings from "../../assets/settings.svg";
import Generate from "../../assets/generate-icon.svg";
import Community from "../../assets/community-icon.svg";
import Player from "../../assets/player-icon.svg";
import Logout from "../../assets/log-out.svg";
import Lightning from "../../assets/lightning.svg";
import "./Menu.scss";

const Menu = () => {
  const context = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    context.audioRef.current && context.audioRef.current.pause();
  }, []);

  return (
    <ModalContainer>
      <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      <AppHeader text="Menu" />
      <Close onClick={() => context.setTab(null)} />

      <div className="menu">
        <div
          className="menu_item"
          onClick={() => context.setTab("account_settings")}
        >
          <span>1</span>
          <span>Settings</span>
          <img src={Settings} alt="Settings" />
        </div>

        <div className="menu_item" onClick={() => context.setTab(null)}>
          <span>2</span>
          <span>Hard Drive</span>
          <img src={Player} alt="Player" />
        </div>

        <div className="menu_item" onClick={() => context.setTab("community")}>
          <span>3</span>
          <span>Community</span>
          <img src={Community} alt="Community" />
        </div>

        <div className="menu_item" onClick={() => context.setTab("generate")}>
          <span>4</span>
          <span>MPFIVE GENERATOR</span>
          <img src={Generate} alt="Generate" />
        </div>

        {!context.admin && (
          <div
            className="menu_item"
            onClick={() => navigate("/subscribe?no_redirect=true")}
          >
            <span>5</span>
            <span>Subscription</span>
            <img src={Lightning} alt="Lightning" />
          </div>
        )}

        <div
          className="menu_item"
          onClick={() => {
            context.setTab(null);
            context.logout();
          }}
        >
          <span>6</span>
          <span>Logout</span>
          <img src={Logout} alt="Logout" />
        </div>

        {context.admin && (
          <>
            <hr />

            <div
              className="menu_item"
              onClick={() => context.setTab("control-panel")}
            >
              <span>6</span>
              <span>Control Panel</span>
              <span>Admin</span>
            </div>

            <div className="menu_item" onClick={() => context.setTab("users")}>
              <span>7</span>
              <span>Users</span>
              <span>Admin</span>
            </div>

            <div
              className="menu_item"
              onClick={() => context.setTab("songs_settings")}
            >
              <span>8</span>
              <span>Songs</span>
              <span>Admin</span>
            </div>
          </>
        )}

        <ThemeToggle />
      </div>
    </ModalContainer>
  );
};

export default Menu;
