import { useContext, useState, useEffect, useRef } from "react";
import { useHttpClient } from "../../../utils/http-hook";
import { Context } from "../../../utils/context";
import { firebaseUpload, firebaseDelete } from "../../../utils/upload-hook";
import ModalContainer from "../../shared/ModalContainer";
import Notification from "../../shared/Notification";
import AppHeader from "../../shared/AppHeader";
import Close from "../../shared/Close";
import Loader from "../../shared/Loader";
import Select from "../../shared/Select";
import Button from "../../shared/Button";
import UploadImg from "../../../assets/upload.svg";
import Clear from "../../../assets/close-accent.svg";
import "./EditAI.scss";
import Input from "../../shared/Input";

const EditAI = () => {
  const context = useContext(Context);
  const songId = context.editSong;
  const song = context.songs.find((s) => s._id === songId);

  const { isLoading, sendRequest, setIsLoading } = useHttpClient();

  const [firebaseId, setFirebaseId] = useState(song.firebaseId);
  const [bpm, setBpm] = useState(song.bpm);
  const [key, setKey] = useState(song.key || "C");
  const [mode, setMode] = useState(song.mode || "major");
  const [length, setLength] = useState(song.length);

  const [melodyPath, setMelodyPath] = useState();
  const [vocalsPath, setVocalsPath] = useState();
  const [kickPath, setKickPath] = useState();
  const [snarePath, setSnarePath] = useState();
  const [bassPath, setBassPath] = useState();
  const [samplesPath, setSamplesPath] = useState();
  const [fxPath, setFxPath] = useState();

  const [melodyURL, setMelodyURL] = useState();
  const [vocalsURL, setVocalsURL] = useState();
  const [kickURL, setKickURL] = useState();
  const [snareURL, setSnareURL] = useState();
  const [bassURL, setBassURL] = useState();
  const [samplesURL, setSamplesURL] = useState();
  const [fxURL, setFxURL] = useState();

  const [AIEnabled, setAIEnabled] = useState();

  useEffect(() => {
    if (song.melodyPath) {
      setMelodyUploaded(true);
      setMelodyPath(song.melodyPath);
      setMelodyURL(song.melodyURL);
    }

    if (song.vocalsPath) {
      setVocalsUploaded(true);
      setVocalsPath(song.vocalsPath);
      setVocalsURL(song.vocalsURL);
    }

    if (song.kickPath) {
      setKickUploaded(true);
      setKickPath(song.kickPath);
      setKickURL(song.kickURL);
    }

    if (song.snarePath) {
      setSnareUploaded(true);
      setSnarePath(song.snarePath);
      setSnareURL(song.snareURL);
    }

    if (song.bassPath) {
      setBassUploaded(true);
      setBassPath(song.bassPath);
      setBassURL(song.bassURL);
    }

    if (song.samplesPath) {
      setSamplesUploaded(true);
      setSamplesPath(song.samplesPath);
      setSamplesURL(song.samplesURL);
    }

    if (song.fxPath) {
      setFxUploaded(true);
      setFxPath(song.fxPath);
      setFxURL(song.fxURL);
    }

    setAIEnabled(song.AIEnabled);
  }, []);

  //stem refs

  //melody
  const [melodyUploaded, setMelodyUploaded] = useState(false);
  const melodyRef = useRef();

  const clearMelody = (e) => {
    e.stopPropagation();
    e.preventDefault();
    melodyRef.current.value = "";
    setMelodyUploaded(false);
  };

  //vocals
  const [vocalsUploaded, setVocalsUploaded] = useState(false);
  const vocalsRef = useRef();

  const clearVocals = (e) => {
    e.stopPropagation();
    e.preventDefault();
    vocalsRef.current.value = "";
    setVocalsUploaded(false);
  };

  //kick
  const [kickUploaded, setKickUploaded] = useState(false);
  const kickRef = useRef();

  const clearKick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    kickRef.current.value = "";
    setKickUploaded(false);
  };

  //snare
  const [snareUploaded, setSnareUploaded] = useState(false);
  const snareRef = useRef();

  const clearSnare = (e) => {
    e.stopPropagation();
    e.preventDefault();
    snareRef.current.value = "";
    setSnareUploaded(false);
  };

  //bass
  const [bassUploaded, setBassUploaded] = useState(false);
  const bassRef = useRef();

  const clearBass = (e) => {
    e.stopPropagation();
    e.preventDefault();
    bassRef.current.value = "";
    setBassUploaded(false);
  };

  //samples
  const [samplesUploaded, setSamplesUploaded] = useState(false);
  const samplesRef = useRef();

  const clearSamples = (e) => {
    e.stopPropagation();
    e.preventDefault();
    samplesRef.current.value = "";
    setSamplesUploaded(false);
  };

  //fx
  const [fxUploaded, setFxUploaded] = useState(false);
  const fxRef = useRef();

  const clearFx = (e) => {
    e.stopPropagation();
    e.preventDefault();
    fxRef.current.value = "";
    setFxUploaded(false);
  };

  //upload ai
  const uploadFileAndGetURL = async (file, path) => {
    const [getUploadTask, getDownloadURL] = firebaseUpload(path, file);
    const uploadTask = getUploadTask();
    await uploadTask;
    return getDownloadURL(uploadTask.snapshot.ref);
  };

  const handleFileUpload = async (ref, category, oldPath, oldURL, active) => {
    let path = "";
    const file = ref.current.files[0];

    if (ref.current.value) {
      path = `Audio/${firebaseId}/${category}.${file.name.split(".").pop()}`;
    }

    if (oldPath && (ref.current.value || !active)) {
      firebaseDelete(oldPath);
    }

    const url = path ? await uploadFileAndGetURL(file, path) : "";
    return {
      path: path || !active ? path : oldPath,
      url: url || !active ? url : oldURL,
    };
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const melody = await handleFileUpload(
        melodyRef,
        "melody",
        melodyPath,
        melodyURL,
        melodyUploaded
      );
      const vocals = await handleFileUpload(
        vocalsRef,
        "vocals",
        vocalsPath,
        vocalsURL,
        vocalsUploaded
      );
      const kick = await handleFileUpload(
        kickRef,
        "kick",
        kickPath,
        kickURL,
        kickUploaded
      );
      const snare = await handleFileUpload(
        snareRef,
        "snare",
        snarePath,
        snareURL,
        snareUploaded
      );
      const bass = await handleFileUpload(
        bassRef,
        "bass",
        bassPath,
        bassURL,
        bassUploaded
      );
      const samples = await handleFileUpload(
        samplesRef,
        "samples",
        samplesPath,
        samplesURL,
        samplesUploaded
      );
      const fx = await handleFileUpload(fxRef, "fx", fxPath, fxURL, fxUploaded);
      saveAIData(melody, vocals, kick, snare, bass, samples, fx);
    } catch (e) {
      context.setError(e.message);
    }
  };

  const saveAIData = async (melody, vocals, kick, snare, bass, samples, fx) => {
    const responseData = await sendRequest(
      `https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/songs/ai/${songId}`,
      "POST",
      JSON.stringify({
        bpm,
        length,
        melody,
        key,
        mode,
        vocals: vocals,
        kick: kick,
        snare: snare,
        bass: bass,
        samples: samples,
        fx: fx,
      }),
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      }
    );

    if (responseData) {
      if (responseData.logout) {
        context.logout();
        return;
      }

      context.setSongs((songs) => {
        const index = songs.findIndex((s) => s._id === context.editSong);
        songs[index] = responseData.song;
        return songs;
      });

      setAIEnabled(responseData.song.AIEnabled);

      setMelodyPath(melody.path);
      setMelodyURL(melody.url);

      setVocalsPath(vocals.path);
      setVocalsURL(vocals.url);

      setKickPath(kick.path);
      setKickURL(kick.url);

      setSnarePath(snare.path);
      setSnareURL(snare.url);

      setBassPath(bass.path);
      setBassURL(bass.url);

      setSamplesPath(samples.path);
      setSamplesURL(samples.url);

      setFxPath(fx.path);
      setFxURL(fx.url);
    }
  };

  return (
    <ModalContainer>
      <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      <AppHeader text="AI Stems" />
      <Close onClick={() => context.setTab("edit_song")} />

      <div className="edit_song">
        <form className="song_form song_form--ai" onSubmit={submitHandler}>
          <div className="ai_inputs">
            <Input
              type="text"
              placeholder="BPM"
              value={bpm}
              onInput={(e) => setBpm(e.target.value)}
            />

            <Input
              type="text"
              placeholder="Length in seconds (e.g. 150)"
              value={length}
              onInput={(e) => setLength(e.target.value)}
            />
          </div>

          <div className="ai_inputs">
            {key && (
              <Select
                data={[
                  { name: "C", value: "C" },
                  { name: "C#", value: "C#" },
                  { name: "Db", value: "Db" },
                  { name: "D", value: "D" },
                  { name: "D#", value: "D#" },
                  { name: "Eb", value: "Eb" },
                  { name: "E", value: "E" },
                  { name: "F", value: "F" },
                  { name: "F#", value: "F#" },
                  { name: "Gb", value: "Gb" },
                  { name: "G", value: "G" },
                  { name: "G#", value: "G#" },
                  { name: "Ab", value: "Ab" },
                  { name: "A", value: "A" },
                  { name: "A#", value: "A#" },
                  { name: "Bb", value: "Bb" },
                  { name: "B", value: "B" },
                ]}
                default={key}
                onChange={(value) => setKey(value)}
              />
            )}

            {mode && (
              <Select
                data={[
                  { name: "major", value: "major" },
                  { name: "minor", value: "minor" },
                ]}
                default={mode}
                onChange={(value) => setMode(value)}
              />
            )}
          </div>

          <div className="ai_grid">
            <div>
              <input
                accept="audio/*"
                type="file"
                ref={melodyRef}
                id="melody"
                onChange={() => setMelodyUploaded(true)}
              />

              <label
                className={melodyUploaded ? "stem active" : "stem"}
                htmlFor="melody"
              >
                <span>Melody</span>
                {!melodyUploaded && <img src={UploadImg} alt="Upload" />}
                {melodyUploaded && (
                  <img src={Clear} alt="Clear" onClick={clearMelody} />
                )}
              </label>
            </div>

            <div>
              <input
                accept="audio/*"
                type="file"
                ref={vocalsRef}
                id="vocals"
                onChange={() => setVocalsUploaded(true)}
              />

              <label
                className={vocalsUploaded ? "stem active" : "stem"}
                htmlFor="vocals"
              >
                <span>Vocals</span>
                {!vocalsUploaded && <img src={UploadImg} alt="Upload" />}
                {vocalsUploaded && (
                  <img src={Clear} alt="Clear" onClick={clearVocals} />
                )}
              </label>
            </div>

            <div>
              <input
                accept="audio/*"
                type="file"
                ref={kickRef}
                id="kick"
                onChange={() => setKickUploaded(true)}
              />
              <label
                className={kickUploaded ? "stem active" : "stem"}
                htmlFor="kick"
              >
                <span>Kick</span>
                {!kickUploaded && <img src={UploadImg} alt="Upload" />}
                {kickUploaded && (
                  <img src={Clear} alt="Clear" onClick={clearKick} />
                )}
              </label>
            </div>

            <div>
              <input
                accept="audio/*"
                type="file"
                ref={snareRef}
                id="snare"
                onChange={() => setSnareUploaded(true)}
              />
              <label
                className={snareUploaded ? "stem active" : "stem"}
                htmlFor="snare"
              >
                <span>Snare</span>
                {!snareUploaded && <img src={UploadImg} alt="Upload" />}
                {snareUploaded && (
                  <img src={Clear} alt="Clear" onClick={clearSnare} />
                )}
              </label>
            </div>

            <div>
              <input
                accept="audio/*"
                type="file"
                ref={bassRef}
                id="bass"
                onChange={() => setBassUploaded(true)}
              />
              <label
                className={bassUploaded ? "stem active" : "stem"}
                htmlFor="bass"
              >
                <span>Bass</span>
                {!bassUploaded && <img src={UploadImg} alt="Upload" />}
                {bassUploaded && (
                  <img src={Clear} alt="Clear" onClick={clearBass} />
                )}
              </label>
            </div>

            <div>
              <input
                accept="audio/*"
                type="file"
                ref={samplesRef}
                id="samples"
                onChange={() => setSamplesUploaded(true)}
              />
              <label
                className={samplesUploaded ? "stem active" : "stem"}
                htmlFor="samples"
              >
                <span>Samples</span>
                {!samplesUploaded && <img src={UploadImg} alt="Upload" />}
                {samplesUploaded && (
                  <img src={Clear} alt="Clear" onClick={clearSamples} />
                )}
              </label>
            </div>

            <div>
              <input
                accept="audio/*"
                type="file"
                ref={fxRef}
                id="fx"
                onChange={() => setFxUploaded(true)}
              />
              <label
                className={fxUploaded ? "stem active" : "stem"}
                htmlFor="fx"
              >
                <span>FX</span>
                {!fxUploaded && <img src={UploadImg} alt="Upload" />}
                {fxUploaded && (
                  <img src={Clear} alt="Clear" onClick={clearFx} />
                )}
              </label>
            </div>
          </div>

          <Button text="Save" />

          <p className="ai_status">
            {AIEnabled ? "AI Enabled" : "AI Disabled"}
          </p>
        </form>

        {isLoading && <Loader />}
      </div>
    </ModalContainer>
  );
};

export default EditAI;
