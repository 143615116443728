import { useState } from "react";
import Chevron from "../../assets/select.svg";
import Close from "../../assets/close-dark.svg";
import "./MultiSelect.scss";

const MultiSelect = (props) => {
  const { values, setValues, data, placeholder } = props;
  const [isActive, setIsActive] = useState(false);

  const removeItem = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setValues(values.filter((v) => v !== value));
  };

  return (
    <div className="multi_select">
      <div
        className="multi_select__header"
        onClick={() => setIsActive((a) => !a)}
      >
        <div className="multi_select__values">
          {values && values.length ? (
            values.map((v) => (
              <div
                className="multi_select__value"
                onClick={(e) => removeItem(e, v)}
              >
                {v} <img src={Close} alt="Close" />
              </div>
            ))
          ) : (
            <p>{placeholder}</p>
          )}
        </div>

        <img src={Chevron} alt="" />
      </div>

      {isActive && (
        <div className="multi_select__body">
          {data.map((d) => (
            <div
              key={d}
              className="multi_select__item"
              onClick={() =>
                !values.find((v) => v === d) && setValues([...values, d])
              }
            >
              {d}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
