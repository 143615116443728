import { useContext } from "react";
import { Context } from "../../utils/context";
import Nav from "../nav/Nav";
import AppHeader from "../shared/AppHeader";
import ActiveSongPreview from "./components/ActiveSongPreview";
import SongProgressBar from "./components/SongProgressBar";
import "./ActiveSong.scss";
import GenerateInstructions from "./components/GenerateInstructions";

const ActiveSong = () => {
  const { songs, activeSong, tab } = useContext(Context);
  const song =
    songs && activeSong ? songs.find((s) => s._id === activeSong) : null;

  return (
    <div
      className={
        tab === "generate"
          ? "col col__2 active_song generate"
          : "col col__2 active_song"
      }
    >
      <Nav />
      {tab !== "generating" && tab !== "generate" && (
        <AppHeader text="NOW PLAYING" />
      )}

      {tab === "generate" && <AppHeader text="Loading..." />}

      {song && (
        <>
          {tab === "generate" ? (
            <GenerateInstructions />
          ) : (
            <ActiveSongPreview song={song} />
          )}
          <SongProgressBar />
        </>
      )}
    </div>
  );
};

export default ActiveSong;
